import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class CajaClienteService {
  private _saldoCuenta = new BehaviorSubject<number>(0);
  private _saldoCuentaCorriente = new BehaviorSubject<number>(0);
  private _pagarCuentaCorriente = new BehaviorSubject<boolean>(false);

  saldoCuenta = this._saldoCuenta.asObservable();
  saldoCuentaCorriente = this._saldoCuentaCorriente.asObservable()
  pagarCuentaCorriente = this._pagarCuentaCorriente.asObservable()

  modificarSaldoCuenta(nuevoSaldo:number){
    this._saldoCuenta.next(nuevoSaldo)
  }

  modificarSaldoCuentaCorriente(nuevoSaldo:number){
    this._saldoCuentaCorriente.next(nuevoSaldo)
  }

  sumarSaldoCuenta(saldoASumar:number){
    const nuevoSaldo = this._saldoCuenta.getValue() + saldoASumar
    this._saldoCuenta.next(nuevoSaldo)
  }

  restarSaldoCuenta(saldoARestar:number){
    const nuevoSaldo = this._saldoCuenta.getValue() - saldoARestar
    this._saldoCuenta.next(nuevoSaldo)
  }

  modificarPagoCuentaCorriente(modificarCuentaCorriente:boolean){
    this._pagarCuentaCorriente.next(modificarCuentaCorriente)
  }
}