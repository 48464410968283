import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class CargarSaldoService {
  private _pagarConCargaSaldo = new BehaviorSubject<boolean>(false);

  pagarConCargaSaldo = this._pagarConCargaSaldo.asObservable();

  pagarConCargarSaldo(){
    this._pagarConCargaSaldo.next(true)
  }

  deshabilitarPagarConCargarSaldo(){
    this._pagarConCargaSaldo.next(false)
  }
}